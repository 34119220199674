<template>
  <div class="detail-container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.detail-container {
  width: 1440px;
  margin: 70px auto;
}
</style>
